import React, { FunctionComponent, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Hidden, Drawer, List, ListItem, ListItemText, IconButton, useScrollTrigger } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faTwitterSquare, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useStaticQuery, graphql, Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import Img from "gatsby-image";
import styled from '@emotion/styled';
import ShoppingCartButton from './shopping-cart-button';

const useStyles = makeStyles((theme: any) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 201,
    },
    toolbar: theme.mixins.toolbar,
}));

interface NavBarProps {
    allowTransparent: boolean;
    backgroundColor: string;
    color: string;
    shoppingCart: boolean;
}

const LogoDiv = styled.div`
    flex-grow:1;
    padding: 4px 0px;
`;

const ToolbarHeightCorrect = styled.div`
    padding-top: 74px;
`;

const SocialIcon = styled(FontAwesomeIcon)`
    width: 0.875em;
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
`;

const NavTextLink = styled<typeof Link, { color: string }>(Link, {
    shouldForwardProp: prop => prop !== "color"
})(props => `
    color: ${props.color};
    text-decoration: none;
`);

const NavAppBar = styled(AppBar, {
    shouldForwardProp: prop => prop !== "transparent" && prop !== "background"
})((props: { transparent: boolean, background: string }) => `
    background-color:${props.transparent ? 'transparent' : props.background};
    transition: background-color 0.5s ease-in-out;
`);

const NotCaps = styled(Typography)`
    text-transform:none;
`;

const NotCapsLIT = styled(ListItemText)`
    text-transform:none;
`;

const AppDrawerStyled = styled.div((props: { color: string, backgroundColor: string }) => `
    background-color:${props.backgroundColor};
    color:${props.color};
`);

const NavBar: FunctionComponent<NavBarProps> = (props) => {
    const classes = useStyles({});
    const [drawerOpen, setDrawerOpen] = useState(false);

    const transparent = !useScrollTrigger({
        disableHysteresis: true,
        threshold: 1
    }) && props.allowTransparent;

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                title
                }
            }
            allMenuJson {
                edges {
                node {
                    label
                    link
                }
                }
            }
            seo: generalJson(block: {eq: "seo" }) {
                logo {
                    childImageSharp{
                        fixed (height:60){
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            social: generalJson (block: { eq: "social" }) {
                facebook
                twitter
                instagram
                youtube
                linkedin
            }
        }
  `);

    const toggleDrawer = (open: boolean) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    return (
        <>
            <NavAppBar elevation={transparent ? 0 : 4} transparent={transparent} background={props.backgroundColor}
                position="fixed" className={classes.appBar}>
                <Toolbar>
                    <LogoDiv><NavTextLink color={props.color} to="/"><Img fixed={data.seo.logo.childImageSharp.fixed} /></NavTextLink></LogoDiv>
                    <Hidden smDown implementation="css">
                        {data.allMenuJson.edges.map((x: { node: { link: string; label: React.ReactNode; }; }) =>
                            <Button color={props.color} key={x.node.link} to={x.node.link}
                                component={Link} size="medium">
                                <NotCaps variant="h6" component="span">{x.node.label}</NotCaps>
                            </Button>
                        )}
                    </Hidden>
                    {props.shoppingCart && <ShoppingCartButton color={props.color} />}
                    <Hidden smDown implementation="css">
                        {data.social.facebook &&
                            <a href={data.social.facebook} target="_blank" rel="noopener">
                                <IconButton><SocialIcon icon={faFacebookSquare} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.twitter &&
                            <a href={data.social.twitter} target="_blank" rel="noopener">
                                <IconButton><SocialIcon icon={faTwitterSquare} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.instagram &&
                            <a href={data.social.instagram} target="_blank" rel="noopener">
                                <IconButton><SocialIcon icon={faInstagram} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.linkedin &&
                            <a href={data.social.linkedin} target="_blank" rel="noopener">
                                <IconButton><FontAwesomeIcon icon={faLinkedin} color={props.color} /></IconButton>
                            </a>
                        }
                        {data.social.youtube &&
                            <a href={data.social.youtube} target="_blank" rel="noopener">
                                <IconButton><FontAwesomeIcon icon={faYoutube} color={props.color} /></IconButton>
                            </a>
                        }
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <IconButton
                            color={props.color}
                            aria-label="open drawer"
                            onClick={toggleDrawer(!drawerOpen)}
                            edge="start"
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                    </Hidden>
                </Toolbar>
            </NavAppBar>
            <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer(false)}>
                <AppDrawerStyled backgroundColor={props.backgroundColor} color={props.color}>
                    <ToolbarHeightCorrect />
                    <List>
                        {data.allMenuJson.edges.map((x: { node: { link: string; label: React.ReactNode; }; }) =>
                            <ListItem button key={x.node.link} to={x.node.link} component={Link} onClick={toggleDrawer(false)}>
                                <NotCapsLIT color="white">{x.node.label}</NotCapsLIT>
                            </ListItem>
                        )}
                        <ListItem>
                            {data.social.facebook &&
                                <a href={data.social.facebook} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faFacebookSquare} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.twitter &&
                                <a href={data.social.twitter} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faTwitterSquare} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.instagram &&
                                <a href={data.social.instagram} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faInstagram} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.linkedin &&
                                <a href={data.social.linkedin} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faLinkedin} color={props.color} /></IconButton>
                                </a>
                            }
                            {data.social.youtube &&
                                <a href={data.social.youtube} target="_blank" rel="noopener">
                                    <IconButton><FontAwesomeIcon icon={faYoutube} color={props.color} /></IconButton>
                                </a>
                            }
                        </ListItem>
                    </List>
                </AppDrawerStyled>
            </Drawer>
        </>)
};

export default NavBar;
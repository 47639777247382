import React, { useContext } from "react";
import { IconButton, Badge } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { ShopContext } from "../context/shop-context-provider";
import { Link } from "gatsby";

interface ShoppingCartButtonProps {
    color: string;
}

const ShoppingCartButton: React.FC<ShoppingCartButtonProps> = (props) => {
    const { checkout } = useContext(ShopContext);

    var totalItems = checkout.lineItems.reduce((prev, current) => {
        return prev + current.quantity;
    }, 0)


    return <Link to="/cart">
        <IconButton>
            <Badge badgeContent={totalItems} color="primary">
                <FontAwesomeIcon icon={faShoppingCart} color={props.color} size="xs"></FontAwesomeIcon>
            </Badge>
        </IconButton>
    </Link>;
}

export default ShoppingCartButton;
import React, { FunctionComponent } from "react";
import { useTheme, Theme, Box, Grid, Typography, Container, Button, IconButton } from "@material-ui/core";
import Section from "./section";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faTwitterSquare, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import styled from "@emotion/styled";

type bp = import('@material-ui/core/Box').BoxProps;
const CenterWhenSmall = styled<React.ComponentType<bp>, { theme: Theme }>(Box)(props =>
    (`
    justify-content: flex-start;

    ${props.theme.breakpoints.down("xs")} {
        justify-content: center;
        align-items: center;
    }
`));

const ByLink = styled.a<{ color: string }>(props => (`
    text-decoration: none;
    color: ${props.color};
`));

const NotCaps = styled(Typography)`
    text-transform:none;
`;

const NoMinWidth = styled(Button)`
    min-width:32px;
`;

type FooterProps = FooterContainerProps
    & Partial<FooterImageBlockProps>
    & Partial<FooterContactBlockProps>
    & Partial<FooterTextBlockProps>
    & FooterLinkBlockProps;

interface FooterContainerProps {
    variant: "3-Image-Links-Contact" | "3-Text-Links-Contact"
    copyright: string;
    color?: string;
    bgColor?: string;
    policies: ReadonlyArray<SiteLink>;
}

interface FooterImageBlockProps {
    link?: string;
    tagline: string;
    logo: import("gatsby-source-filesystem").FileSystemNode;
}

const FooterImageBlock: React.FC<FooterImageBlockProps> = (props) => {

    let child = <Img fixed={(props.logo.childImageSharp as any).fixed} />;

    if (props.link) {
        child = (<a href={`${props.link}`} target="__blank" rel="noopener">
            {child}
        </a>);
    }

    return (<div>
        <Typography variant="h5" align="center">{props.tagline}</Typography>
        {child}
    </div>);
}

interface FooterTextBlockProps {
    text: string;
    logo: import("gatsby-source-filesystem").FileSystemNode;
}

const FooterTextBlock: React.FC<FooterTextBlockProps> = (props) => {
    return (<Box p={1} maxWidth={425}>
        <Typography variant="body2" component="span">{props.text}</Typography>
    </Box>);
}

interface SiteLink {
    label: string;
    link: string
}

interface FooterLinkBlockProps {
    siteLinks: ReadonlyArray<SiteLink>;
}

const FooterLinkBlock: React.FC<FooterLinkBlockProps> = (props) => {
    return <>
        {props.siteLinks.map(x =>
            <NoMinWidth color="inherit" key={x.link} to={x.link}
                component={Link}>
                <NotCaps variant="h6" component="span">{x.label}</NotCaps>
            </NoMinWidth>
        )}
    </>;
}

interface FooterContactBlockProps {
    color: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
    youtube: string;
}

const FooterContactBlock: React.FC<FooterContactBlockProps> = (props) => {
    return <>
        <Typography variant="h6" align="right">{props.addressLine1}</Typography>
        <Typography variant="h6" align="right">{props.addressLine2}</Typography>
        <Typography variant="h6" align="right">{props.city}, {props.state} {props.zip}</Typography>
        {props.email && 
        <Box py={1}>
            <Typography variant="h6" align="right"><ByLink color={props.color} href={`mailto:${props.email}`}>{props.email}</ByLink></Typography>
        </Box>}
        <Box display="flex" justifyContent="flex-end" mr={-2} pt={1}>
            {props.facebook &&
                <a href={props.facebook} target="_blank" rel="noopener">
                    <IconButton><FontAwesomeIcon icon={faFacebookSquare} color={props.color} /></IconButton>
                </a>
            }
            {props.twitter &&
                <a href={props.twitter} target="_blank" rel="noopener">
                    <IconButton><FontAwesomeIcon icon={faTwitterSquare} color={props.color} /></IconButton>
                </a>
            }
            {props.instagram &&
                <a href={props.instagram} target="_blank" rel="noopener">
                    <IconButton><FontAwesomeIcon icon={faInstagram} color={props.color} /></IconButton>
                </a>
            }
            {props.linkedin &&
                <a href={props.linkedin} target="_blank" rel="noopener">
                    <IconButton><FontAwesomeIcon icon={faLinkedin} color={props.color} /></IconButton>
                </a>
            }
            {props.youtube &&
                <a href={props.youtube} target="_blank" rel="noopener">
                    <IconButton><FontAwesomeIcon icon={faYoutube} color={props.color} /></IconButton>
                </a>
            }
        </Box>
    </>;
}

const Footer: FunctionComponent<FooterProps> = (props) => {
    const theme = useTheme<Theme>();
    var bgColor = props.bgColor ?? theme.palette.primary.dark;
    var color = props.color ?? theme.palette.primary.contrastText;

    const renderBlock = (block) => {
        switch (block) {
            case "Image":
                return (
                    <Grid key={block} item xs={12} sm={5}>
                        <CenterWhenSmall theme={theme} display="flex" alignItems="center" pt={1}>
                            <FooterImageBlock logo={props.logo} tagline={props.tagline} link={props.link} />
                        </CenterWhenSmall>
                    </Grid>);
            case "Links":
                return (
                    <Grid key={block} item xs={12} sm={4}>
                        <CenterWhenSmall theme={theme} display="flex" flexDirection="column" alignItems="flex-start">
                            <FooterLinkBlock siteLinks={props.siteLinks} />
                        </CenterWhenSmall>
                    </Grid>
                )

            case "Contact":
                return (
                    <Grid key={block} item xs={12} sm={3}>
                        <CenterWhenSmall theme={theme} display="flex" flexDirection="column" pt={1}>
                            <FooterContactBlock
                                color={color}
                                addressLine1={props.addressLine1}
                                addressLine2={props.addressLine2}
                                city={props.city}
                                state={props.state}
                                zip={props.zip}
                                email={props.email}
                                facebook={props.facebook}
                                twitter={props.twitter}
                                instagram={props.instagram}
                                linkedin={props.linkedin}
                                youtube={props.youtube}
                            />
                        </CenterWhenSmall>
                    </Grid>);

            case "Text":
                return (
                    <Grid key={block} item xs={12} sm={5}>
                        <CenterWhenSmall theme={theme} display="flex" alignItems="center" pt={1}>
                            <FooterTextBlock text={props.text} logo={props.logo} />
                        </CenterWhenSmall>
                    </Grid>);
        }
    }

    const renderPolicies = () => {
        if (!props.policies || props.policies.length == 0) {
            return (<></>)
        } else {
            return (<Box py={1}>
                <Grid container justify="center">
                    {props.policies.map(p => 
                    <Grid item container xs={12} sm={3} justify="center" key={p.link} >
                        <NoMinWidth color="inherit" to={p.link} component={Link}>
                            <NotCaps variant="body1" component="span">{p.label}</NotCaps>
                        </NoMinWidth>
                    </Grid>)}
                </Grid>
            </Box>);
        }
    };

    const footerColumns = props.variant.split("-");
    let children = [];

    for (var i = 0; i < footerColumns.length; i++) {
        children.push(renderBlock(footerColumns[i]));
    }

    return (
        <Section noBottom tightTop bgcolor={bgColor} color={color}>
            <Container>
                <Box pb={2}>
                    <Grid container>
                        {children}
                    </Grid>
                </Box>
                {renderPolicies()}
                <Box py={1}>
                    <Typography align="center" variant="caption" component="div">{props.copyright}</Typography>
                    <Typography align="center" variant="caption" component="div"><ByLink color={color} href="https://www.atldmg.com" target="_blank" rel="noopener">Website By Atlanta Digital Marketing Group</ByLink></Typography>
                </Box>
            </Container>
        </Section>
    )
}

export default Footer;
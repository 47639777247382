import React, { useState, useEffect } from "react";
import Client from "shopify-buy";
import queryString from "query-string";

export interface LineItem {
    id: string,
    title: string,
    variant: {
        title: string,
        id: string,
        price: string,
        image: {
            src: string
        },
        selectedOptions: ReadonlyArray<{
            name: string
            value: string
        }>
    }
    quantity: number
}

interface ShopState {
    adding: boolean;
    checkout: {
        id: string,
        lineItems: ReadonlyArray<LineItem>
    };
    checkoutUrl:  string;
    addToCart: (variantId: string, quantity: number) => void;
    removeFromCart: (lineItemId: string) => void;
    updateCart: (lineItemId: string, quantity: number) => void;
}

export const ShopContext = React.createContext<ShopState>({} as ShopState);

let domain = `${process.env.GATSBY_SHOP_NAME}`;
if(!domain.includes(".")) {
    // this is making a broad assumption that a dot means we have a full domain.
    domain = `${domain}.myshopify.com`;
}

const shopifyClient = Client.buildClient({
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    domain: domain,
});

const ShopContextProvider: React.FC = (props) => {

    let initialStoreState = {
        shopifyClient,
        adding: false,
        checkout: {
            id: '',
            lineItems: [],
        },
        checkoutUrl: '',
        products: [],
        shop: {}
    }

    const [store, updateStore] = useState(initialStoreState);

    useEffect(() => {
        const isBrowser = typeof window !== 'undefined';

        const initializeCheckout = async () => {
            // Check for an existing cart.
            const existingCheckoutID = isBrowser
                ? localStorage.getItem('shopify_checkout_id')
                : null;

            let source = undefined;
            let medium = undefined;
            let campaign = undefined;

            if(isBrowser) {
                const { utm_source, utm_medium, utm_campaign } = queryString.parse(location.search);

                if(utm_source && utm_source.length > 0) {
                    source = utm_source.toString();
                    sessionStorage.setItem('utm_source', source);
                } else {
                    source = sessionStorage.getItem('utm_source');
                }

                if(utm_medium && utm_medium.length > 0) {
                    medium = utm_medium.toString();
                    sessionStorage.setItem('utm_medium', medium);
                } else {
                    medium = sessionStorage.getItem('utm_medium');
                }

                if(utm_campaign && utm_campaign.length > 0) {
                    campaign = utm_campaign.toString();
                    sessionStorage.setItem('utm_campaign', campaign);
                } else {
                    campaign = sessionStorage.getItem('utm_campaign');
                }
            }

            const setCheckoutInState = async checkout => {
                let url = checkout.webUrl;

                if (isBrowser) {
                    localStorage.setItem('shopify_checkout_id', checkout.id);

                    const checkoutUrl = queryString.parseUrl(checkout.webUrl);
    
                    const attrs = [];

                    if(source) {
                        checkoutUrl.query.utm_source = source;
                        attrs.push({key: "source", value: source});
                    }
    
                    if(medium) {
                        checkoutUrl.query.utm_medium = medium;
                        attrs.push({key: "medium", value: medium});
                    }
    
                    if(campaign) {
                        checkoutUrl.query.utm_campaign = campaign;
                        attrs.push({key: "campaign", value: campaign});
                    }
    

                    url = queryString.stringifyUrl(checkoutUrl);
                    
                    if(attrs.length > 0) {
                        await updateAttributes(checkout.id, { customAttributes: attrs});
                    }
                }

                updateStore(prevState => {
                    return { ...prevState, checkout, checkoutUrl: url }
                })
            }

            const updateAttributes = (id, input) => store.shopifyClient.checkout.updateAttributes(id, input);
            const createNewCheckout = () => store.shopifyClient.checkout.create()
            const fetchCheckout = id => store.shopifyClient.checkout.fetch(id)

            if (existingCheckoutID) {
                try {
                    const checkout = await fetchCheckout(existingCheckoutID)
                    // Make sure this cart hasn’t already been purchased.
                    if (!checkout.completedAt) {
                        await setCheckoutInState(checkout)
                        return
                    }
                } catch (e) {
                    localStorage.setItem('shopify_checkout_id', null)
                }
            }

            const newCheckout = await createNewCheckout();
            await setCheckoutInState(newCheckout);
        }

        if (isBrowser)
            initializeCheckout()

    }, [store.shopifyClient.checkout]);


    const addToCart = (variantId: string, quantity: number) => {
        if (!variantId || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
        }

        updateStore(prevState => {
            return { ...prevState, adding: true }
        })

        const { checkout, shopifyClient } = store;
        const checkoutId = checkout.id;

        const lineItemsToUpdate = [
            { variantId, quantity: quantity },
        ];

        return shopifyClient.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
                updateStore(prevState => {
                    return { ...prevState, checkout, adding: false }
                })
            });
    };

    const removeFromCart = (lineItemId: string) => {
        if (!lineItemId) {
            console.error('lineItemId is required.');
            return;
        }

        const { checkout, shopifyClient } = store;

        return shopifyClient.checkout
            .removeLineItems(checkout.id, [lineItemId])
            .then(res => {
                updateStore(prevState => {
                    return { ...prevState, checkout: res }
                })
            });
    }

    const updateCart = (lineItemId: string, quantity: number) => {
        const lineItemsToUpdate = [
            { id: lineItemId, quantity: quantity },
        ];

        const { checkout, shopifyClient } = store;

        return shopifyClient.checkout
            .updateLineItems(checkout.id, lineItemsToUpdate)
            .then(res => {
                updateStore(prevState => {
                    return { ...prevState, checkout: res }
                })
            });
    }

    const launchCheckout = () => {
        const { checkoutUrl } = store;

        window.open(checkoutUrl);
    }

    return <ShopContext.Provider value={{
        adding: store.adding,
        checkout: store.checkout,
        checkoutUrl: store.checkoutUrl,
        addToCart,
        removeFromCart,
        updateCart,
    }}>
        {props.children}
    </ShopContext.Provider>;
}

export default ShopContextProvider;